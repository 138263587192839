import { MapComponentProps } from 'components/common/MapComponent/MapComponentTypes';
import { createContext, useContext, useState } from 'react';

type MapPropertiesProviderProps = {
  children: React.ReactNode;
  defaultProperties: MapComponentProps;
};

const MapPropertiesContext = createContext<MapComponentProps | undefined>(undefined);
const MapSetPropertiesContext = createContext<
  React.Dispatch<React.SetStateAction<MapComponentProps>> | undefined
>(undefined);

const MapPropertiesProvider = ({ children, defaultProperties }: MapPropertiesProviderProps) => {
  const [properties, setProperties] = useState(defaultProperties);

  return (
    <MapSetPropertiesContext.Provider value={setProperties}>
      <MapPropertiesContext.Provider value={properties}>{children}</MapPropertiesContext.Provider>
    </MapSetPropertiesContext.Provider>
  );
};

const useMapProperties = () => {
  const context = useContext(MapPropertiesContext);
  if (!context) {
    throw new Error('useMapProperties must be used within a MapPropertiesProvider');
  }
  return context;
};

const useSetMapProperties = () => {
  const context = useContext(MapSetPropertiesContext);
  if (!context) {
    throw new Error('useSetMapProperties must be used within a MapPropertiesProvider');
  }
  return context;
};

export { MapPropertiesProvider, useMapProperties, useSetMapProperties };
