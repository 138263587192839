import { useMapEffect } from 'hooks/useMap/useMapGL/MapGLContext';
import { useMapRoadAttributesLayers } from 'hooks/useMap/useMapRoadAttributesLayers';
import { emptyFeature } from 'hooks/useMap/useRoutePlannerMap/useRoutePlannerMapSource';
import { loadRoadAttributesImages } from 'lib/map/featureTiles/loadRoadAttributesImages';
import { LayerSpecification, SourceSpecification } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';
import { useCallback } from 'react';

export enum RoadAttributesLayers {
  LINES = 'road-attributes-lines',
  MARKERS = 'road-attributes-markers',
  CLUSTERS = 'road-attributes-clusters'
}

export const useMapFeaturesSourcesAndLayers = (controllerId: MapControllers) => {
  const layers = useMapRoadAttributesLayers();

  const clusterProperties = {
    feature_length: ['max', ['get', 'feature_length']],
    icon_size_sm: ['max', ['get', 'icon_size_sm']],
    icon_size_md: ['max', ['get', 'icon_size_md']],
    icon_size_lg: ['max', ['get', 'icon_size_lg']],
    image_key: ['coalesce', ['get', 'image_key']],
    icon_text_size_sm: ['max', ['get', 'icon_text_size_sm']],
    icon_text_size_lg: ['max', ['get', 'icon_text_size_lg']],
    icon_text_abbrev: ['coalesce', ['get', 'icon_text_abbrev']],
    icon_text: ['coalesce', ['get', 'icon_text']],
    icon_text_color: ['coalesce', ['get', 'icon_text_color']],
    is_visible: ['coalesce', ['get', 'is_visible']]
  };

  const sources = new Map([
    [
      RoadAttributesLayers.LINES,
      {
        type: 'geojson',
        data: emptyFeature
      }
    ],
    [
      RoadAttributesLayers.CLUSTERS,
      {
        type: 'geojson',
        data: emptyFeature,
        cluster: true,
        clusterRadius: 20,
        clusterProperties
      }
    ]
  ]);
  // Use Recoil Callback to manage state updates in Recoil

  // Use useCallback to memoize the effect callback
  const memoizedEffect = useCallback(
    async (mapInstance) => {
      loadRoadAttributesImages(mapInstance);

      sources.forEach((value, key) => {
        if (!mapInstance.getSource(key)) {
          mapInstance.addSource(key, value as SourceSpecification);
        }
      });

      layers.forEach((layer) => {
        const belowLayerId = layer.id === RoadAttributesLayers.LINES ? 'aerialway' : undefined;
        if (!mapInstance.getLayer(layer.id)) {
          mapInstance.addLayer(layer as LayerSpecification, belowLayerId);
        }
      });
    },
    [layers, sources]
  );

  // Apply useMapEffect with memoizedEffect
  useMapEffect(memoizedEffect, controllerId);
};
