import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const HeightLimitIcon = React.memo((props: GlyphProps) => (
  <Glyph width={33} height={33} {...props}>
    <g clipPath="url(#clip0_3183_3441)">
      <g filter="url(#filter0_d_3183_3441)">
        <path
          d="M1.91421 15.0858L15.0858 1.91421C15.8668 1.13317 17.1332 1.13316 17.9142 1.91421L31.0858 15.0858C31.8668 15.8668 31.8668 17.1332 31.0858 17.9142L17.9142 31.0858C17.1332 31.8668 15.8668 31.8668 15.0858 31.0858L1.91421 17.9142C1.13317 17.1332 1.13316 15.8668 1.91421 15.0858Z"
          fill="#FFD600"
        />
        <path
          d="M1.91421 15.0858L15.0858 1.91421C15.8668 1.13317 17.1332 1.13316 17.9142 1.91421L31.0858 15.0858C31.8668 15.8668 31.8668 17.1332 31.0858 17.9142L17.9142 31.0858C17.1332 31.8668 15.8668 31.8668 15.0858 31.0858L1.91421 17.9142C1.13317 17.1332 1.13316 15.8668 1.91421 15.0858Z"
          stroke="black"
          strokeWidth="2"
        />
      </g>
      <path
        d="M16.5 5.5L14.5 7.5H18.5L16.5 5.5Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 27.5L18.5 25.5H14.5L16.5 27.5Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51318 13.5293H9.35622L10.624 16.6188H10.6879L11.9557 13.5293H13.7987V18.9839H12.3498V15.8305H12.3072L11.0927 18.9413H10.2191L9.00466 15.8092H8.96205V18.9839H7.51318V13.5293ZM15.9174 18.9839H14.3194L16.1198 13.5293H18.144L19.9444 18.9839H18.3464L17.1532 15.0315H17.1106L15.9174 18.9839ZM15.6191 16.8319H18.6234V17.9399H15.6191V16.8319ZM22.0571 13.5293L22.952 15.1167H22.9946L23.9001 13.5293H25.5514L23.9214 16.2566L25.6153 18.9839H23.9214L22.9946 17.3646H22.952L22.0251 18.9839H20.3419L22.0145 16.2566L20.3952 13.5293H22.0571Z"
        fill="black"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3183_3441"
        x="-2.67163"
        y="-1.67157"
        width="38.3433"
        height="38.3431"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_3183_3441" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3183_3441"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3183_3441">
        <rect width="33" height="33" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

HeightLimitIcon.displayName = 'HeightLimitIcon';
