import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const LayersIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1581_314)">
      <path
        d="M7.43813 2.28777C7.78512 2.08436 8.21496 2.08436 8.56195 2.28777L14.0417 5.50004L8.56195 8.71231C8.21496 8.91572 7.78512 8.91572 7.43813 8.71231L1.95837 5.50004L7.43813 2.28777Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70837 8L1.95837 10.5L7.43813 13.7123C7.78512 13.9157 8.21496 13.9157 8.56195 13.7123L14.0417 10.5L10.2269 8"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1581_314">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

LayersIcon.displayName = 'LayersIcon';

export { LayersIcon };
