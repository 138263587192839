import { RoadAttributesLayers } from 'hooks/useMap/useMapFeaturesSourcesAndLayers';
import { getIconImageExpression } from 'lib/mapbox';

const zoomThresholdMd = 12;
const zoomThresholdLg = 16;

export const roadAttrClustersFilter = [
  '>=',
  ['zoom'],
  [
    'case',
    ['>', ['get', 'feature_length'], 5000], // If longer than 5000m...
    7, // ...disappear at zoom 7.
    ['>', ['get', 'feature_length'], 500], // Otherwise, if longer than 500m...
    8, // ...disappear at zoom 8.
    9 // Otherwise, disappear at zoom 9.
  ]
];

export const roadAttrMarkersFilter = ['>', ['get', 'feature_length'], 1000];

const symbolLayout = {
  'icon-image': getIconImageExpression('image_key'),
  'icon-size': [
    'step',
    ['zoom'],
    // 0,
    // zoomThresholdSm, // At far zoom: no setting. (Keep this commented out.) Hiding icons at far zoom levels is handled on the `filter` property.
    ['/', ['get', 'icon_size_sm'], 128],
    zoomThresholdMd, // At medium zoom: small.
    ['/', ['get', 'icon_size_md'], 128],
    zoomThresholdLg, // At close zoom: normal.
    ['/', ['get', 'icon_size_lg'], 128]
  ],
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-rotation-alignment': 'viewport',
  'text-font': ['Inter Black'],
  'text-offset': [0, 0.08],
  'text-rotation-alignment': 'viewport',
  'text-field': [
    'step',
    ['zoom'],
    ['get', 'icon_text_abbrev'],
    zoomThresholdLg,
    ['get', 'icon_text']
  ],
  'text-size': [
    'step',
    ['zoom'],
    0,
    zoomThresholdMd,
    ['get', 'icon_text_size_sm'],
    zoomThresholdLg,
    ['get', 'icon_text_size_lg']
  ]
};

export const useMapRoadAttributesLayers = () => [
  {
    id: RoadAttributesLayers.LINES,
    type: 'line',
    source: RoadAttributesLayers.LINES,
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-opacity': [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        10,
        0,
        18,
        ['get', 'line_opacity']
      ],
      'line-color': ['get', 'accent_color_mid'],
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        7.5, // At far zoom...
        0, // ...invisible.
        9,
        1,
        14,
        4,
        18,
        8,
        20, // At close zoom...
        14 //...bold.
      ]
    }
  },
  {
    id: RoadAttributesLayers.CLUSTERS,
    type: 'symbol',
    source: RoadAttributesLayers.CLUSTERS,
    filter: roadAttrClustersFilter,
    layout: symbolLayout,
    paint: {
      'text-color': ['get', 'icon_text_color'],
      'icon-opacity': ['interpolate', ['linear', 1], ['zoom'], 6, 0, 12, 1]
    }
  },
  {
    id: RoadAttributesLayers.MARKERS,
    type: 'symbol',
    source: RoadAttributesLayers.CLUSTERS,
    layout: symbolLayout,
    filter: roadAttrMarkersFilter,
    paint: {
      'text-color': ['get', 'icon_text_color'],
      'icon-opacity': [
        'interpolate',
        ['linear', 1],
        ['zoom'],
        6,
        ['case', ['boolean', ['feature-state', 'show-markers'], false], 0, 0],
        9,
        ['case', ['boolean', ['feature-state', 'show-markers'], false], 1, 1]
      ]
    }
  }
];
