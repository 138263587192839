import { CloseButton } from 'components/common/Button';
import { Divider } from 'components/common/Divider';
import { BottomToTopTransition } from 'components/common/Transitions/BottomToTopTransition';
import { MetaText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

type MapLayersCardProps = ChildrenProp &
  VisibilityProp &
  ParentClassNameProp & { onClose?: GenericHandler };

const useStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => ['z-map absolute bottom-0 right-0 mr-11 w-60', 'drop-shadow', props?.className]
});

export const MapLayersCard: React.FC<MapLayersCardProps> = memo(
  ({ children, show = false, className, onClose }) => {
    const styles = useStyles({ className });
    return (
      <BottomToTopTransition show={show} className={styles.root}>
        <PanelFlex fullWidth align="end" padding={false}>
          <PanelItem
            enableBackground
            enableBorder
            intent="primary"
            justify="start"
            rounded="LG"
            padding
            paddingY="LG"
            paddingX="MD"
            column
            align="start">
            <PanelFlex row fullWidth justify="between" padding={false}>
              <MetaText bold>Map Settings</MetaText>
              <CloseButton onClose={onClose} />
            </PanelFlex>
            <Divider />
            {children}
          </PanelItem>
        </PanelFlex>
      </BottomToTopTransition>
    );
  }
);

MapLayersCard.displayName = 'MapLayersCard';
