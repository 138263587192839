import { bearingToHeading } from '@truckmap/common';
import bearing from '@turf/bearing';
import { Coord, Feature, FeatureCollection, GeometryCollection, Units } from '@turf/helpers';
import length from '@turf/length';

export const turfHeading = (start: Coord, end: Coord, options?: { final?: boolean }): number => {
  return bearingToHeading(bearing(start, end, options));
};

const mergedUnits = (options?: { units?: Units }): Record<string, unknown> => {
  const merged: Record<string, unknown> = {
    units: 'meters',
    ...options
  };
  return merged;
};

export const turfLength = (
  shape: Feature<unknown> | FeatureCollection<unknown> | GeometryCollection,
  options = {}
): number => {
  return length(shape, mergedUnits(options));
};
