import { GlyphProps } from 'components/common/Glyph';
import { mapRestrictionsPanelVisibleAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';

export type TruckRestrictionsProps = {
  icon?: React.MemoExoticComponent<(props: GlyphProps) => React.JSX.Element>;
  title: string;
};

export const useTruckRestrictions = (controllerId) => {
  const [restrictionsVisible, setRestrictionsVisible] = useRecoilState(
    mapRestrictionsPanelVisibleAtom(controllerId)
  );

  const containerRef = useRef();

  const toggleMapSettings = (enable?: boolean) => {
    if (enable !== undefined) {
      return setRestrictionsVisible(enable);
    }

    setRestrictionsVisible((visible) => !visible);
  };

  return {
    toggleMapSettings,
    show: restrictionsVisible,
    containerRef
  };
};
