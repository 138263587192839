import mapboxgl from 'mapbox-gl';

export const addMapImage = async (mapInstance: mapboxgl.Map, slug, iconSrc) => {
  return new Promise((resolve, reject) => {
    mapInstance.loadImage(iconSrc, (error, image) => {
      if (error) reject(error);

      if (!mapInstance.hasImage(slug)) {
        resolve(mapInstance.addImage(slug, image));
      }
    });
  });
};
