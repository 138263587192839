import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const TrucksYesIcon = React.memo((props: GlyphProps) => (
  <Glyph width={33} height={33} {...props}>
    <g clipPath="url(#clip0_3185_3392)">
      <g filter="url(#filter0_d_3185_3392)">
        <circle cx="17" cy="16.5" r="16" fill="#373CC5" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9.5C8.44772 9.5 8 9.94772 8 10.5V20.5C8 21.0523 8.44772 21.5 9 21.5H19C19.5523 21.5 20 21.0523 20 20.5V18V10.5C20 9.94772 19.5523 9.5 19 9.5H9ZM22 13.5C21.4477 13.5 21 13.9477 21 14.5V20.5C21 21.0523 21.4477 21.5 22 21.5H25C25.5523 21.5 26 21.0523 26 20.5V16.8028C26 16.6054 25.9416 16.4123 25.8321 16.2481L24.2969 13.9453C24.1114 13.6671 23.7992 13.5 23.4648 13.5H22Z"
        fill="white"
      />
      <circle cx="11.5" cy="22" r="2" fill="white" stroke="#373CC5" />
      <circle cx="23.5" cy="22" r="2" fill="white" stroke="#373CC5" />
    </g>
    <defs>
      <filter
        id="filter0_d_3185_3392"
        x="-2"
        y="-1.5"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_3185_3392" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3185_3392"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3185_3392">
        <rect width="33" height="33" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Glyph>
));

TrucksYesIcon.displayName = 'TrucksYesIcon';
