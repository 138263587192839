import { Checkbox } from 'components/common/Input/SelectInput/Checkbox';
import { SmallText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { TruckRestrictionsProps } from 'hooks/useTruckRestrictions/useTruckRestrictions';
import { memo } from 'react';

type MapLayersOptionProps = {
  checked?: boolean;
} & ClickEventProp &
  TruckRestrictionsProps;

export const MapLayersOption = memo(({ icon, checked, title, onClick }: MapLayersOptionProps) => {
  const Icon = icon;
  return (
    <button onClick={onClick} className="w-full">
      <PanelItem
        row
        justify="between"
        fullWidth
        enableBackground
        enableHover
        intent="primary"
        active={checked}
        paddingY="XS"
        spacing="LG"
        className="group">
        <PanelFlex row padding={false} fullWidth>
          {icon && <Icon className="text-lg shrink-0" />}
          <SmallText align="left" active={checked} enableHover="group">
            {title}
          </SmallText>
        </PanelFlex>
        <Checkbox checked={checked} />
      </PanelItem>
    </button>
  );
});

MapLayersOption.displayName = 'MapLayersOption';
