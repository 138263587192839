export type MapLayerName =
  | 'buildings-3d'
  | 'traffic'
  | 'truck-designated'
  | 'truck-prohibited'
  | 'height-restricted'
  | 'weight-restricted';

export enum RoadAttrImageKey {
  '3d-buildings' = '/assets/road-attributes/3d-buildings.png',
  'closed-road' = '/assets/road-attributes/closed-road.map.png',
  'maxheight' = '/assets/road-attributes/max-height.map.png',
  'maxweight' = '/assets/road-attributes/max-weight.map.png',
  'toll' = '/assets/road-attributes/toll.map.png',
  'traffic' = '/assets/road-attributes/traffic.png',
  'truck-yes' = '/assets/road-attributes/truck-designated-road.map.png',
  'truck-designated' = '/assets/road-attributes/truck-designated-road.map.png',
  'truck-no' = '/assets/road-attributes/truck-restricted-road.map.png'
}

export enum RoadAttrFeatureTypeEnum {
  'border-crossing' = 'border-crossing',
  'hazmat-delivery' = 'hazmat-delivery',
  'hazmat-designated' = 'hazmat-designated',
  'hazmat-destination' = 'hazmat-destination',
  'hazmat-local' = 'hazmat-local',
  'hazmat-no' = 'hazmat-no',
  'maxheight' = 'maxheight',
  'maxweight' = 'maxweight',
  'railroad-crossing' = 'railroad-crossing',
  'toll' = 'toll',
  'truck-delivery' = 'truck-delivery',
  'truck-designated' = 'truck-designated',
  'truck-destination' = 'truck-destination',
  'truck-local' = 'truck-local',
  'truck-no' = 'truck-no',
  'truck-yes' = 'truck-yes'
}

export enum RESTRICTION_TYPE {
  BORDER_CROSSING = 'border-crossing',
  HAZMAT_DELIVERY = 'hazmat-delivery',
  HAZMAT_DESIGNATED = 'hazmat-designated',
  HAZMAT_DESTINATION = 'hazmat-destination',
  HAZMAT_LOCAL = 'hazmat-local',
  HAZMAT_NO = 'hazmat-no',
  MAXHEIGHT = 'maxheight',
  MAXWEIGHT = 'maxweight',
  RAILROAD_CROSSING = 'railroad-crossing',
  TOLL = 'toll',
  TRUCK_DELIVERY = 'truck-delivery',
  TRUCK_DESIGNATED = 'truck-designated',
  TRUCK_DESTINATION = 'truck-destination',
  TRUCK_LOCAL = 'truck-local',
  TRUCK_NO = 'truck-no',
  TRUCK_YES = 'truck-yes'
}

export enum RESTRICTION_TYPE_CONTENTFUL_KEY {
  'border-crossing' = 'BORDER_CROSSING',
  'hazmat-delivery' = 'HAZMAT_DELIVERY',
  'hazmat-designated' = 'HAZMAT_DESIGNATED',
  'hazmat-destination' = 'HAZMAT_DESTINATION',
  'hazmat-local' = 'HAZMAT_LOCAL',
  'hazmat-no' = 'HAZMAT_NO',
  'maxheight' = 'MAXHEIGHT',
  'maxweight' = 'MAXWEIGHT',
  'railroad-crossing' = 'RAILROAD_CROSSING',
  'toll' = 'TOLL',
  'truck-delivery' = 'TRUCK_DELIVERY',
  'truck-designated' = 'TRUCK_DESIGNATED',
  'truck-destination' = 'TRUCK_DESTINATION',
  'truck-local' = 'TRUCK_LOCAL',
  'truck-no' = 'TRUCK_NO',
  'truck-yes' = 'TRUCK_YES'
}

export enum AVAILABLE_RESTRICTION_TYPE_KEYS_PANEL_MESSAGE {
  'toll' = 'TOLL',
  'truck-delivery' = 'TRUCK_DELIVERY',
  'truck-designated' = 'TRUCK_DESIGNATED',
  'truck-destination' = 'TRUCK_DESTINATION',
  'truck-local' = 'TRUCK_LOCAL',
  'truck-no' = 'TRUCK_NO',
  'truck-yes' = 'TRUCK_YES'
}
