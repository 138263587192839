import { MapLayersButton } from 'components/map/MapButton/MapLayersButton';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import {
  TruckRestrictionsSettingsProps,
  useTruckRestrictionsStyles
} from 'components/TruckRestrictions/TruckRestrictionsSettings';
import { useTruckRestrictions } from 'hooks/useTruckRestrictions/useTruckRestrictions';
import { MapControllers } from 'models/MapControllers';
import { memo } from 'react';

export const TruckRestrictionsMapButton = memo(
  ({ buttonClassName, className }: TruckRestrictionsSettingsProps) => {
    const controllerId = useMapControllerContext();
    const styles = useTruckRestrictionsStyles({
      isPlaceSearch: controllerId === MapControllers.PLACE_SEARCH,
      buttonClassName,
      className
    });
    const { toggleMapSettings } = useTruckRestrictions(controllerId);

    return (
      <MapLayersButton
        onClick={(e) => {
          e?.preventDefault();
          toggleMapSettings();
          return;
        }}
        recoilAtomFamily={controllerId}
        className={styles.mapLayersButton}
      />
    );
  }
);

TruckRestrictionsMapButton.displayName = 'TruckRestrictionsMapButton';
