import { getRoadAttributeDisplayInfo, RoadAttrFeatureType } from '@truckmap/common';

import { MapLayerName } from 'lib/map/featureTiles/types';
import { ImageKey } from 'types/map';

export type TileDisplayInfo = {
  title: string;
  imageKey?: ImageKey;
};

export const getTileDisplayInfo = (type: RoadAttrFeatureType): TileDisplayInfo => {
  return getRoadAttributeDisplayInfo(type) as TileDisplayInfo;
};

/**
 * Maps each road attribute type to a map layer, to allow looking up a feature
 * type to determine if it is part of a visible map layer.
 */
export const featureTypeToLayerNameDict: Partial<Record<RoadAttrFeatureType, MapLayerName>> = {
  'truck-yes': 'truck-designated',
  'truck-designated': 'truck-designated',
  'truck-delivery': 'truck-prohibited',
  'truck-destination': 'truck-prohibited',
  'truck-local': 'truck-prohibited',
  'truck-no': 'truck-prohibited',
  maxheight: 'height-restricted',
  maxweight: 'weight-restricted'
};
