import { RoadAttrFeatureType } from '@truckmap/common';
import { Button } from 'components/common/Button';
import { ClientSideOnly } from 'components/common/ClientSideOnly';
import { Divider } from 'components/common/Divider';
import { mapInvisibleLayersAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { Translation } from 'components/common/Translation';
import { SmallText } from 'components/common/Typography';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { MapLayersCard } from 'components/map/MapLayersCard/MapLayersCard';
import { MapLayersOption } from 'components/map/MapLayersCard/MapLayersOption';
import { TripActionsProps } from 'components/RoutePlanner/TripActions';
import { TranslationConfig, useTranslation } from 'hooks/useContentful';
import {
  TruckRestrictionsProps,
  useTruckRestrictions
} from 'hooks/useTruckRestrictions/useTruckRestrictions';
import { HeightLimitIcon } from 'icons/truckRestrictions/HeightLimitIcon';
import { TrucksNoIcon } from 'icons/truckRestrictions/TrucksNoIcon';
import { TrucksYesIcon } from 'icons/truckRestrictions/TrucksYesIcon';
import { WeightLimitIcon } from 'icons/truckRestrictions/WeightLimitIcon';
import { makeStyles } from 'lib/makeStyles';
import { MapControllers } from 'models/MapControllers';
import { memo } from 'react';
import { useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';
import { featureTypeToLayerNameDict } from 'types/roadAttributes';
import { TranslationKeys } from 'types/translationKeys';

export type TruckRestrictionsSettingsProps = {
  buttonClassName?: string;
  disableStyles?: boolean;
} & TripActionsProps &
  ParentClassNameProp;

export type TruckRestrictionsSettingsStyleProps = {
  isPlaceSearch?: boolean;
  isTruckRestrictionsPanelOpen?: boolean;
} & Omit<TruckRestrictionsSettingsProps, 'controllerId'>;

export const useTruckRestrictionsStyles = makeStyles<TruckRestrictionsSettingsStyleProps>()({
  mapLayersButton: ({ buttonClassName, isPlaceSearch }) => [
    {
      'absolute bottom-3 right-2 ': isPlaceSearch
    },
    buttonClassName
  ],
  mapSettingsCard: ({ className, isPlaceSearch }) => [
    {
      'absolute bottom-3 ml-4': isPlaceSearch,
      'mb-2 ': !isPlaceSearch
    },
    className
  ]
});

export const truckRestrictionsIconMap = (
  t: (key: TranslationKeys, config?: TranslationConfig) => string
) =>
  new Map<RoadAttrFeatureType, TruckRestrictionsProps>([
    ['maxheight', { icon: HeightLimitIcon, title: t('MAX_HEIGHT_LIMIT') }],
    ['maxweight', { icon: WeightLimitIcon, title: t('MAX_WEIGHT_LIMIT') }],
    ['truck-designated', { icon: TrucksYesIcon, title: t('DESIGNATED_TRUCK_ROUTE') }],
    ['truck-no', { icon: TrucksNoIcon, title: t('NO_TRUCKS_ALLOWED') }]
  ]);

export const TruckRestrictionsSettings = memo(
  ({ controllerId, buttonClassName, className }: TruckRestrictionsSettingsProps) => {
    const { t } = useTranslation();
    const resetInvisibleLayers = useResetRecoilState(mapInvisibleLayersAtom(controllerId));
    const invisibleLayers = useRecoilValue(mapInvisibleLayersAtom(controllerId));
    const styles = useTruckRestrictionsStyles({
      isPlaceSearch: controllerId === MapControllers.PLACE_SEARCH,
      buttonClassName,
      className
    });

    const onClickTruckRestrictionOption = useRecoilCallback(({ set }) => (id: string) => {
      const newState = invisibleLayers.includes(id)
        ? invisibleLayers.filter((restriction) => restriction !== id)
        : [...invisibleLayers, id];

      set(mapInvisibleLayersAtom(controllerId), newState);
    });

    const { toggleMapSettings, show } = useTruckRestrictions(controllerId);

    return (
      <ClientSideOnly>
        <MapLayersCard
          className={styles.mapSettingsCard}
          show={show}
          onClose={(e) => {
            e?.preventDefault();
            toggleMapSettings(false);
            return;
          }}>
          <SmallText medium color="primary">
            <Translation textKey="ENABLE_LAYERS" />
          </SmallText>
          {Array.from(truckRestrictionsIconMap(t).entries()).map(([id, { icon, title }]) => (
            <MapLayersOption
              key={`map_layer_${id}`}
              checked={!invisibleLayers.includes(featureTypeToLayerNameDict[id])}
              icon={icon}
              title={title}
              onClick={(e) => {
                e?.preventDefault();
                onClickTruckRestrictionOption(featureTypeToLayerNameDict[id]);
                return;
              }}
            />
          ))}
          <Divider spacing="SM" />
          <PanelFlex fullWidth>
            <Button
              isSmall
              disabled={invisibleLayers.length === 0}
              onClick={(e) => {
                e?.preventDefault();
                resetInvisibleLayers();
                return;
              }}>
              <Translation textKey="RESET" />
            </Button>
            <Button
              isSmall
              fullWidth
              dark
              onClick={(e) => {
                e?.preventDefault();
                toggleMapSettings(false);
                return;
              }}>
              <Translation textKey="DONE" />
            </Button>
          </PanelFlex>
        </MapLayersCard>
      </ClientSideOnly>
    );
  }
);

TruckRestrictionsSettings.displayName = 'TruckRestrictionsSettings';
