import { Transition } from '@headlessui/react';
import React from 'react';

type Props = {
  show?: boolean;
  className?: string;
} & ChildrenProp;

const transitionProps = {
  enter: 'transform-gpu delay-050 transition duration-[400ms]',
  enterFrom: 'transform-gpu translate-y-[150%]',
  enterTo: 'transform-gpu translate-y-0',
  leave: 'transform-gpu duration-200 transition ease-in-out',
  leaveFrom: 'transform-gpu translate-y-0',
  leaveTo: 'transform-gpu translate-y-[150%]'
};

const BottomToTopTransition: React.FC<Props> = ({ children, show, className }) => {
  return (
    <Transition className={className} show={show} {...transitionProps}>
      {children}
    </Transition>
  );
};

BottomToTopTransition.displayName = 'BottomToTopTransition';

export { BottomToTopTransition };
