import { MapComponentProps } from 'components/common/MapComponent/MapComponentTypes';
import { MapPropertiesProvider } from 'components/common/MapComponent/MapPropertiesContext';
import { MapComponentOptions } from 'components/common/MapComponent/options/MapComponentOptions';
import { Map } from 'components/map/Map';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { makeStyles } from 'lib/makeStyles';
import dynamic from 'next/dynamic';

export const useStyles = makeStyles({
  mapLoader: ['z-map', 'absolute left-[50%] top-2 translate-x-50']
});

const MapLoaderComponent = dynamic(
  () => import('components/map/MapLoader/MapLoader').then((mod) => mod.MapLoader),
  { ssr: false }
);

export const MapComponent = (props: MapComponentProps) => {
  const { mapStyle, isLoading, mapProps, latitude, longitude } = props;
  const controllerId = useMapControllerContext();
  const styles = useStyles();

  return (
    <MapPropertiesProvider defaultProperties={props}>
      <>
        {isLoading && <MapLoaderComponent show={isLoading} className={styles.mapLoader} />}
        <MapComponentOptions />
        <Map
          controllerId={controllerId}
          className={mapStyle}
          latitude={latitude}
          longitude={longitude}
          {...mapProps}
        />
      </>
    </MapPropertiesProvider>
  );
};

MapComponent.displayName = 'MapComponent';
