import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

export const TrucksNoIcon = React.memo((props: GlyphProps) => (
  <Glyph width={32} height={32} {...props}>
    <g clipPath="url(#clip0_3183_3377)">
      <g filter="url(#filter0_d_3183_3377)">
        <circle cx="16" cy="16" r="16" fill="white" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
        fill="#D12702"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9C7.44772 9 7 9.44772 7 10V20C7 20.5523 7.44772 21 8 21H18C18.5523 21 19 20.5523 19 20V17.5V10C19 9.44772 18.5523 9 18 9H8ZM21 13C20.4477 13 20 13.4477 20 14V20C20 20.5523 20.4477 21 21 21H24C24.5523 21 25 20.5523 25 20V16.3028C25 16.1054 24.9416 15.9123 24.8321 15.7481L23.2969 13.4453C23.1114 13.1671 22.7992 13 22.4648 13H21Z"
        fill="black"
      />
      <circle cx="10.5" cy="21.5" r="2" fill="black" stroke="white" />
      <circle cx="22.5" cy="21.5" r="2" fill="black" stroke="white" />
      <path d="M5.5 5.5L27 27" stroke="#D12702" strokeWidth="2" />
    </g>
    <defs>
      <filter
        id="filter0_d_3183_3377"
        x="-3"
        y="-2"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_3183_3377" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3183_3377"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3183_3377">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

TrucksNoIcon.displayName = 'TrucksNoIcon';
