import {
  buildFeatureCollection,
  buildLatLon,
  convertUnits,
  RoadAttrFeature,
  roadAttributesGetTileInfos,
  roadAttributesGetTilesDict,
  RoadAttrTile,
  RoadAttrTileInfo
} from '@truckmap/common';
import turfBbox from '@turf/bbox';
import turfCenter from '@turf/center';
import { featureCollection, point, Position } from '@turf/helpers';
import { currentUserSettingsAtom } from 'components/common/MapComponent/recoil/mapSettingsAtom';
import { RoadAttributesLayers } from 'hooks/useMap/useMapFeaturesSourcesAndLayers';
import { getMapInstance } from 'hooks/useMap/useMapGL/MapGLContext';
import { log } from 'lib/log';
import {
  roadAttributeLinesFeatureCollection,
  roadAttributePointsFeatureCollection
} from 'lib/map/featureTiles/getFeatureCollections';
import mapboxGl, { GeoJSONSource } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';
import { atomFamily, selectorFamily, SerializableParam } from 'recoil';

export type RoadAttributesState = {
  /** A map of tile IDs to tiles. */
  tilesDict: Record<number, RoadAttrTile>;
  /** The position around which the last set of tiles was fetched. */
  updatedAround?: Position;
  /** The time at which the last set of tiles was fetched. */
  updatedAt?: number;
  /**
   * Any features located under the user's tap, represented as a map of tile
   * feature IDs to features.
   */
};

export type RoadAttributesAtomProps = {
  featureTiles: RoadAttributesState;
  invisibleLayers: string[];
};

export const selectedFeaturesDictAtom = atomFamily<Map<string, RoadAttrFeature>, string>({
  key: 'selectedFeaturesDictAtom',
  default: new Map()
});

const addItemsToMapInstance =
  (controllerId: MapControllers) =>
  ({ onSet, getLoadable }) =>
    onSet((newValue) => {
      const {
        featureTiles: { tilesDict },
        invisibleLayers
      } = newValue;

      const currentUserOptions = getLoadable(currentUserSettingsAtom);
      // using the rever logic here since it's more easy to hide

      if (!currentUserOptions.contents) return;

      const linesFeatureCollection = roadAttributeLinesFeatureCollection(
        tilesDict,
        invisibleLayers,
        currentUserOptions.contents
      );

      const pointsFeatureCollection = roadAttributePointsFeatureCollection(
        tilesDict,
        invisibleLayers,
        currentUserOptions.contents
      );

      const mapInstance = getMapInstance(controllerId);

      if (mapInstance?.getSource(RoadAttributesLayers.LINES)) {
        (mapInstance.getSource(RoadAttributesLayers.LINES) as GeoJSONSource).setData(
          linesFeatureCollection
        );
      }

      if (mapInstance?.getSource(RoadAttributesLayers.CLUSTERS)) {
        (mapInstance.getSource(RoadAttributesLayers.CLUSTERS) as GeoJSONSource).setData(
          pointsFeatureCollection
        );
      }

      mapInstance.triggerRepaint();
    });

export const fetchFeatureTilesSelector = selectorFamily<RoadAttributesState, string>({
  key: 'fetchFeatureTiles',
  get:
    (controllerId: MapControllers) =>
    async ({ get }) => {
      const {
        featureTiles: { tilesDict }
      } = get(roadAttributesAtom(controllerId));
      const tilesDictPrev = tilesDict;
      let tilesDictNext: Record<number, RoadAttrTile> = { ...tilesDictPrev };
      const mapInstance = getMapInstance(controllerId);
      const center = mapInstance.getCenter();
      const bounds = mapInstance.getBounds();
      const now = Date.now();

      try {
        const bbox = turfBbox(
          featureCollection([
            point([bounds.getSouthWest().lng, bounds.getSouthWest().lat]),
            point([bounds.getNorthEast().lng, bounds.getNorthEast().lat])
          ])
        );
        const tileInfos = await roadAttributesGetTileInfos({ bbox });
        const tileInfosDedup: RoadAttrTileInfo[] = [];
        for (const t of tileInfos) {
          if (!tilesDictPrev[t.id]) {
            tileInfosDedup.push(t);
          }
        }
        log(
          'map',
          `Fetched info for ${tileInfos.length} tiles; ${tileInfosDedup.length} new tiles need to be fetched`
        );
        if (tileInfosDedup.length === 0) {
          return;
        }

        log('map', `Fetching tiles based on ${tileInfosDedup.length} infos`);
        const tilesDict = await roadAttributesGetTilesDict(tileInfos);
        tilesDictNext = { ...tilesDictNext, ...tilesDict };
      } catch (e) {
        console.error('Error fetching road attributes:', e);
        return {
          tilesDict: [],
          updatedAround: undefined,
          updatedAt: undefined
        };
      }

      const distanceInvalidateAt = convertUnits(100, 'km', 'm');
      log(
        'map',
        `Checking for expired or distant tiles (invalidate at ${distanceInvalidateAt.toFixed(0)} m)`
      );

      for (const [tileId, tile] of Object.entries(tilesDictNext)) {
        const expiresAt = new Date(tile.sourceInfo.expires);
        if (now > expiresAt.getTime()) {
          delete tilesDictNext[tileId];
        }

        const bbox = tile.sourceInfo.bbox as [number, number, number, number];
        const tileCenterPosition = turfCenter(
          buildFeatureCollection([
            buildLatLon({ coordinates: [bbox[0], bbox[1]] }),
            buildLatLon({ coordinates: [bbox[2], bbox[3]] })
          ])
        );

        const [lng, lat] = tileCenterPosition.geometry.coordinates;
        const centerPos = new mapboxGl.LngLat(lng, lat);

        const distanceAway = mapInstance.getCenter().distanceTo(centerPos);
        if (distanceAway > distanceInvalidateAt) {
          delete tilesDictNext[tileId];
        }
      }

      return {
        tilesDict: tilesDictNext,
        updatedAround: [center.lng, center.lat],
        updatedAt: now
      };
    }
});

export const roadAttributesAtom = atomFamily<RoadAttributesAtomProps, SerializableParam>({
  key: 'roadAttributesAtom',
  default: {
    invisibleLayers: [],
    featureTiles: {
      tilesDict: {},
      updatedAround: undefined,
      updatedAt: undefined
    }
  },
  effects: (controllerId: MapControllers) => [addItemsToMapInstance(controllerId)]
});
