import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedFeaturesDictAtom } from 'recoil/roadAttributes/roadAttributesAtom';

export const useTruckRestrictionsPanel = ({ controllerId }) => {
  const featuresDict = useRecoilValue(selectedFeaturesDictAtom(controllerId));

  const roadNameFeaturesDict = useMemo(() => {
    if (!featuresDict) {
      return {};
    }

    const features = Array.from(featuresDict.values());

    return groupBy(features, (o) => o.properties.road_name);
  }, [featuresDict]);

  const uniqueRoadNames = useMemo(() => {
    return Object.keys(roadNameFeaturesDict).sort(
      (a, b) => roadNameFeaturesDict[a].length - roadNameFeaturesDict[b].length
    );
  }, [roadNameFeaturesDict]);

  const uniqueFeatures = useMemo(() => {
    if (!featuresDict) {
      return [];
    }

    const features = Array.from(featuresDict.values());

    return sortBy(
      uniqBy(features, (o) => `${o.properties.road_name}-${o.properties.type}`),
      ['properties.road_name', 'properties.type']
    ).slice(0, 4);
  }, [featuresDict]);

  return {
    roadNameFeaturesDict,
    uniqueRoadNames,
    uniqueFeatures
  };
};
