import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { MapButton, MapButtonProps, useMapButtonStyles } from 'components/map/MapButton/MapButton';
import { useTranslation } from 'hooks/useContentful';
import { LayersIcon } from 'icons/monochrome/LayersIcon';
import { makeStyles } from 'lib/makeStyles';
import { MapControllers } from 'models/MapControllers';
import { memo } from 'react';

export type MapLayersButtonProps = {
  recoilAtomFamily: MapControllers;
} & ParentClassNameProp &
  Pick<MapButtonProps, 'rounded' | 'onClick' | 'show'>;

const useStyles = makeStyles<ParentClassNameProp>()({
  icon: (props) => ['text-neutralIcon', 'm-1/2', props.className]
});

export const MapLayersButton = memo(
  ({ onClick, className, rounded, show = true }: MapLayersButtonProps) => {
    const mapButtonStyles = useMapButtonStyles();
    const styles = useStyles({ className: mapButtonStyles.icon });
    const { t } = useTranslation();

    return (
      <MapButton
        onClick={onClick}
        rounded={rounded}
        show={show}
        className={className}
        aria-label={t('TRUCK_RESTRICTIONS_LAYERS')}>
        <PanelFlex padding={false} paddingX="XS" spacing="XS">
          <LayersIcon className={styles.icon} />
        </PanelFlex>
      </MapButton>
    );
  }
);

MapLayersButton.displayName = 'MapLayersButton';
