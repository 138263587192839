import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { makeStyles } from 'lib/makeStyles';
import { memo, useCallback, useMemo } from 'react';

export type MapButtonProps = {
  as?: 'button' | 'div';
  disablePadding?: boolean;
  disableStyle?: boolean;
  rounded?: boolean;
} & ChildrenProp &
  ParentClassNameProp &
  JSX.IntrinsicElements['button'] &
  VisibilityProp;

type MapButtonStyleProps = Pick<MapButtonProps, 'disablePadding' | 'disableStyle' | 'rounded'> &
  ParentClassNameProp;

export const useMapButtonStyles = makeStyles<MapButtonStyleProps>()({
  root: (props) => [
    'max-h-min',
    {
      'p-1.5': !props?.disablePadding && !props?.disableStyle,
      'border border-primaryBorder hover:primaryBorderHover': !props?.disableStyle,
      'bg-white hover:bg-neutralBackground': !props?.disableStyle,
      'rounded-full': !props?.disableStyle,
      'md:rounded-md': !props?.disableStyle && !props?.rounded
    },
    'shadow-lg',
    'hover:cursor-pointer',
    props?.className
  ],
  inner: 'flex justify-center align-middle',
  icon: 'text-lg'
});

export const MapButton: React.FC<MapButtonProps> = memo(
  ({
    as = 'button',
    children,
    onClick,
    className,
    disableStyle = false,
    disablePadding = false,
    rounded = false,
    show = true,
    ...props
  }) => {
    const mapButtonStyles = useMapButtonStyles({
      className,
      disableStyle,
      disablePadding,
      rounded
    });
    const isButton = as === 'button';
    // above map z index (49)
    const style = { zIndex: 50 };

    const handleClick = useCallback(
      (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        return onClick && onClick(e);
      },
      [onClick]
    );

    const innerElement = useMemo(
      () => <div className={mapButtonStyles.inner}>{children}</div>,
      [children]
    );

    return (
      <OpacityTransition show={show}>
        {isButton ? (
          <button
            style={style}
            onClick={isButton && handleClick}
            className={mapButtonStyles.root}
            {...props}>
            {innerElement}
          </button>
        ) : (
          <div style={style} className={mapButtonStyles.root}>
            {innerElement}
          </div>
        )}
      </OpacityTransition>
    );
  }
);

MapButton.displayName = 'MapButton';
